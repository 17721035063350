const crypto = require('crypto')

export function getEncryptedPassword (password) {
  var keyStr = 'Cal#45E42@a'
  const algorithm = 'aes-256-cfb'
  const hash = crypto.createHash('sha256')
  hash.update(keyStr)
  const keyBytes = hash.digest()
  const iv = crypto.randomBytes(16)
  const cipher = crypto.createCipheriv(algorithm, keyBytes, iv)
  const enc = [iv, cipher.update(password, 'utf8')]
  enc.push(cipher.final())
  password = Buffer.concat(enc).toString('base64')
  return password
}
export function getDecryptedPassword (password) {
  var keyStr = 'Cal#45E42@a'
  const algorithm = 'aes-256-cfb'
  const hash = crypto.createHash('sha256')
  hash.update(keyStr)
  const keyBytes = hash.digest()
  const contents = Buffer.from(password, 'base64')
  const iv = contents.slice(0, 16)
  const textBytes = contents.slice(16)
  const decipher = crypto.createDecipheriv(algorithm, keyBytes, iv)
  let res = decipher.update(textBytes, '', 'utf8')
  res += decipher.final('utf8')
  return res
}

<template>
  <div id="inspire">
    <div>
      <div fluid class="flex  justify-center items-center p-0 h-screen px-4 bg-gray-100 bg-opacity-70" style="backdrop-filter: blur(20px);">
        <div class="bg-white cust_card rounded card_design px-4 py-7">
          <div class="flex-wrap">
            <div class="text-left">
              <div class="mb-3 p-0 flex justify-center">
                <div class=" text-text2 mb-4" style="font-size:32px;letter-spacing: 1px;">
                  <span class=""><img class="w-60" src="@/assets/images/perimeter.svg"></span>
                </div>
              </div>
              <div class="p-0">
                <form @submit.prevent="" autocomplete="false" ref="form">
                  <div class="flex-wrap">
                    <div :class="inputFoucus1 ? 'border-2 border-primary2' : emailError !== '' ? 'border-2 border-error' :  'border border-gray2 m-px'" id="Einputbox" class="flex items-center bg-white rounded-md  px-2 py-1.5 ">
                      <p v-if="inputFoucus1"><i class="absolute ml-3 -mt-3 fas fa-envelope h-5 w-5 text-primary2"></i></p>
                      <span v-if="!inputFoucus1"><i class="absolute ml-3 -mt-3 fas fa-envelope h-5 w-5 text-text2"></i></span>
                      <input  id="username" class="w-96 pl-12 py-2 cust_text_field heading-4 text-text2" v-model="emailOrphoneNumber" placeholder="Enter Email"
                      type="text"
                      :autocomplete="false"
                      @focus="setBorder1(true)"
                      @blur="setBorder1(false)"
                      @input="setBorder1('input')"
                      @keyup.enter="LoginNow()"
                      >
                    </div>
                    <p class="text-error heading-7" v-if="emailError !== ''">{{emailError}}</p>
                    <div id="Einputbox" :class="inputFoucus2 ? 'border-2 border-primary2' : errorMessage !== '' ? 'border-2 border-error' : 'border border-gray2 m-px'" class="flex bg-white rounded-md  px-2 py-1.5 items-center mt-4">
                      <p v-if="inputFoucus2"><i class="absolute ml-3 -mt-3 fas fa-lock h-5 w-5 text-primary2"></i></p>
                      <span v-if="!inputFoucus2"><i class="absolute ml-3 -mt-3 fas fa-lock h-5 w-5 text-text2"></i></span>
                      <input id="useridPassword"  class="pl-12 w-96 py-2 cust_text_field heading-4 text-text2" placeholder="Enter your password"
                      :type="showPassword ? 'text' : 'password'"
                      v-model="password"
                      @click:append="showPassword = !showPassword"
                      @focus="setBorder2(true)"
                      @blur="setBorder2(false)"
                      @input="setBorder2('input')"
                      @keyup.enter="LoginNow()"
                      >
                      <span v-if="!showPassword" @click="showPassword = !showPassword" class="cursor-pointer"><img height="30px" width="30px" src="@/assets/images/svg/hide-pass.svg" alt="clearInput"></span>
                      <span v-else @click="showPassword = !showPassword" class="cursor-pointer"><img height="20px" width="30px" src="@/assets/images/svg/show-pass.svg" alt="clearInput"></span>
                    </div>
                    <p class="text-error heading-7" v-if="errorMessage !== ''">{{errorMessage}}</p>
                      <div class="py-1.5 mb-px mt-2">
                        <button
                          class="w-full heading-3 bg-primary2 text-white rounded-full py-2"
                          large
                          @click="LoginNow">Login
                        </button>
                      </div>
                  </div>
                </form>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loader
      v-if="$store.getters.showLoader"
      v-bind:data="$store.getters.loaderMessage"
    ></loader>
    <snakBar
        v-if="$store.getters.showAlert"
        v-bind:status="$store.getters.showAlert"
        v-bind:message="$store.getters.alertMessage"
        v-bind:color="$store.getters.alertColor"
      >
      </snakBar>
  </div>
</template>
<script>
import {getEncryptedPassword} from '@/utils/encrytPassword'
import { validateEmail} from "@/utils/validation.js";
import * as animationData from '@/assets/animation/pinjump.json'
import {setCookiesWithExpire, removeCookies} from '@/utils/cookies.js'
import Loader from '@/View/components/Loader.vue'
import MyApp from '@/api/App.js'
import snakBar from '@/View/components/SnakBar.vue'
import API from '@/api/App.js'
// import Policy from './components/policy'
// import TermsCondition from './components/terms.vue'
const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export default {
  components: {
    Loader,
    snakBar,
    // TermsCondition,
    // Policy
  },
  data () {
    return {
      emailOrphoneNumber: "",
      emailError: "",
      showCompanyName: false,
      inputFoucus1: false,
      inputFoucus2: false,
      companyLogoUrl: '',
      subdomain: '',
      emailLoginIdentifier: '',
      domainData: {
        companyName: '',
        companyId: 0
      },
      defaultOptions: {loop: false, animationData: animationData},
      animationSpeed: 1,
      alignLayout: 'text-center align-center',
      errorMessage: '',
      password: '',
      showPassword: false,
      errors: {
        password: [v => !!v || 'Please Enter Password'],
        emailError: [v => {
          if (pattern.test(v)) {
            return true || ''
          } else {
            return false || 'Enter valid email'
          }
        }]
      },
      rememberMe: false,
      valid: false,
      lazy: false,
      ConfirmReq: false,
      authToken: '',
      deviceMacAddress: '',
      macDialog: false,
      networkInterfacesDetails: {},
      mobileView: false,
      showTermsAndConditon: false,
      showPolicy: false,
    }
  },
  mounted () {
    this.$root.$on('closeMacDialog', () => {
      this.macDialog = false
    })
    document.title = 'Login'
    // setTimeout(() => {
    //   document.getElementById('useridPassword').focus()
    // }, 500);
  },
  watch: {
    emailOrphoneNumber: {
      handler () {
        console.log('this.emailOrphoneNumber', this.emailOrphoneNumber)
      },
      deep: true
    }
  },
  methods: {
    setBorder1 (val) {
      if (!val) {
          this.inputFoucus1 = false
      } else {
          this.inputFoucus1 = true
      }
      if (val === 'input') {
          this.inputFoucus1 = true
          this.emailError = ''
      }
    },
    setBorder2 (val) {
        if (!val) {
            this.inputFoucus2 = false
        } else {
            this.inputFoucus2 = true
        }
        if (val === 'input') {
          this.inputFoucus2 = true
          this.errorMessage = ''
      }
    },
    forgotPasswordHandler () {
      this.$router.push({name: 'EnterEmailComponent'})
    },
    handleAnimation: function (anim) {
      this.anim = anim
    },
    // LoginNow () {
    //   console.log(this.emailOrphoneNumber)
    //   console.log(this.password)
    //   if (this.emailOrphoneNumber === "") {
    //     this.emailError = "Email Address is required";
    //   }
    //   if (this.password === "") {
    //     this.errorMessage = "Password is required";
    //   }
    //   if (this.emailError === '') {
    //     if (validateEmail(this.emailOrphoneNumber) === false) {
    //       this.emailError = "Please enter valid email";
    //       return;
    //     }
    //   }
    //   if (this.emailError === '' && this.errorMessage === '') {
    //     this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
    //     console.log('password------->>>>', this.password)
    //     MyWorkApp.loginToSpecificCompany(
    //       this.emailOrphoneNumber,
    //       this.encryptedPassword,
    //       response => {
    //         localStorage.removeItem('resendOtpAttemptsCount')
    //         localStorage.removeItem('verifyOtpAttemptsCount')
    //         localStorage.removeItem('emailAddress')
    //         removeCookies('_SEC_PRLG2DICC')
    //         this.$store.dispatch('SetLoader', {status: false, message: ''})
    //         localStorage.removeItem('companyData')
    //         localStorage.setItem('employeeData2', JSON.stringify(response.Data))
    //         setCookiesWithExpire('authToken', response.Data.authToken, 30)
    //         this.$router.push({name: 'dashboard'})
    //       },
    //       error => {
    //         this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
    //         this.$store.dispatch('SetLoader', {status: false, message: ''})
    //       }
    //     )
    //   }
    //   // if (this.emailOrphoneNumber === "") {
    //   //   this.emailError = "Email or Phone Number is required";
    //   // }
    //   // if (this.password === "") {
    //   //   this.errorMessage = "Password is required";
    //   // }
    //   // if (this.emailError === '' && this.errorMessage === '' && this.emailOrphoneNumber.indexOf("@") > -1) {
    //   //   if (validateEmail(this.emailOrphoneNumber) === false) {
    //   //     this.emailError = "Please enter valid email";
    //   //     return;
    //   //   }
    //   // } else if (PhoneNumberValidate(this.emailOrphoneNumber) === false) {
    //   //   this.emailError = "Please enter valid 10 digit mobile number";
    //   //   return;
    //   // }
    // },
    LoginNow () {
      console.log(this.emailOrphoneNumber)
      console.log(this.password)
      if (this.emailOrphoneNumber === "") {
        this.emailError = "Email Address is required";
      }
      if (this.password === "") {
        this.errorMessage = "Password is required";
      }
      if (this.emailError === '') {
        if (validateEmail(this.emailOrphoneNumber) === false) {
          this.emailError = "Please enter valid email";
          return;
        }
      }
      if (this.emailError === '' && this.errorMessage === '') {
        let password
        password = getEncryptedPassword(this.password)
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
        console.log('password------->>>>', this.password)
        MyApp.loginToSpecificCompany(
          this.emailOrphoneNumber,
          password,
          'analytics',
          response => {
            localStorage.removeItem('resendOtpAttemptsCount')
            localStorage.removeItem('verifyOtpAttemptsCount')
            localStorage.removeItem('emailAddress')
            removeCookies('_SEC_PRLG2DICC')
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            localStorage.removeItem('companyData')
            localStorage.setItem('employeeData2', JSON.stringify(response.Data))
            setCookiesWithExpire('authToken', response.Data.authToken, 30)
            this.getPermissionList(response.Data)
            // this.$router.push({name: 'dashboard'})
          },
          error => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      }
      // if (this.emailOrphoneNumber === "") {
      //   this.emailError = "Email or Phone Number is required";
      // }
      // if (this.password === "") {
      //   this.errorMessage = "Password is required";
      // }
      // if (this.emailError === '' && this.errorMessage === '' && this.emailOrphoneNumber.indexOf("@") > -1) {
      //   if (validateEmail(this.emailOrphoneNumber) === false) {
      //     this.emailError = "Please enter valid email";
      //     return;
      //   }
      // } else if (PhoneNumberValidate(this.emailOrphoneNumber) === false) {
      //   this.emailError = "Please enter valid 10 digit mobile number";
      //   return;
      // }
    },
    getPermissionList (userDetail) {
      API.specificUserModuleList(
        userDetail.userId,
          response => {
            let nonAdminRouting = response.Data
            console.log('nonAdminRouting', nonAdminRouting)
            if (nonAdminRouting !== null) {
              let shouldNavigate = false;
              nonAdminRouting.forEach(element => {
                if (element.mainMenuName === 'Cost of Goods Sold') {
                    element.subMenuList.forEach(ele2 => {
                        if (ele2.subMenuName === 'Amazon Sage') {
                            shouldNavigate = true;
                            return; // Exit the loop early once condition is met
                        }
                    });
                    if (shouldNavigate) {
                        this.$router.push({ name: 'Amazon Sage' });
                    } else {
                        this.$router.push({ name: 'dashboard' });
                    }
                }
              });
              if (!shouldNavigate) {
                  this.$router.push({ name: 'dashboard' });
                }
              } else {
                if (userDetail.isAdmin) {
                  this.$router.push({name: 'Amazon Sage'})
                } else {
                  this.$router.push({ name: 'dashboard' });
              }
            }
          },
          error => {
            // console.log(error)
            this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
    },
    removeSavedEmail () {
      this.emailOrphoneNumber = ''
      this.password = ''
      removeCookies('_SEC_EM2DICC')
      removeCookies('_SEC_ZW3DPCC')
      removeCookies('_SEC_PRLG2DICC')
      this.$router.push({name: 'identifier'})
    },
    goToSignup () {
      let url = process.env.SETUP_URL
      window.location.href = url
    }
  },
  beforeDestroy () {
    this.$root.$off('closeMacDialog')
    this.$root.$off('closeEmailDialogEvent')
  },
  computed: {
  }
}
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Saira+Stencil+One&display=swap");
.title {
  font-size: 22px;
  font-weight: 700;
  color: #4169ae;
  margin: 0 0 4px 0;
}
.companyLogo {
  width: 180px;
  height: 100px;
}

.termsAndPolicyTitles {
  color: #139cc4;
  cursor: pointer;
  text-decoration: underline;
}
.logo_color__text {
  color: #139cc4 !important;
}
.saved_email__user {
  display: flex;
  padding: 10px;
  position: relative;
  border: solid lightgray 1px;
  align-items: center;
    border-radius: 4px;
}
.card_design {
  max-width: 500px;
}
.IWidth {
  width: 400px;
}
</style>
